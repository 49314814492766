import * as Yup from 'yup';
import { t } from 'i18next';
import calculateNights from '../../utils/calculateNights';

const validationSchema = Yup.object().shape({
    location: Yup.string().required(t('errorSelectLocation')),
    startDate: Yup.string(t('errorStartDate'))
        .required(t('errorStartDate'))
        .test('is-valid-date', t('errorInvalidStartDate'), (value) => {
            return !isNaN(Date.parse(value));
        })
        .test('is-future-date', t('errorPastDatesNotAllowed'), (value) => {
            if (!value) return true; // Esto se maneja en el 'required'
            const date = new Date(value);
            const today = new Date();
            date.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            return date >= today;
        }),
    endDate: Yup.string(t('errorEndDate'))
        .required(t('errorEndDate'))
        .test('is-valid-date', t('errorInvalidEndDate'), (value) => {
            return !isNaN(Date.parse(value));
        })
        .test('is-after-start-date', t('errorEndDateBeforeStartDate'), function (value) {
            const { startDate } = this.parent;
            if (!value || !startDate) return true; // Se maneja en 'required' o en otros tests
            const start = new Date(startDate);
            const end = new Date(value);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            return end > start;
        })
        .test('is-not-same-date', t('errorSameStartEndDate'), function (value) {
            const { startDate } = this.parent;
            if (!value || !startDate) return true; // Se maneja en 'required' o en otros tests
            const start = new Date(startDate);
            const end = new Date(value);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            return end.getTime() !== start.getTime();
        }),
    numberOfGuests: Yup.number()
        .required(t('errorNumberOfGuests'))
        .min(0, t('errorNoGuests'))
        .max(
            Yup.ref('$maxGuestsAllowed'),
            t('errorMaxGuests', { maxGuests: Yup.ref('$maxGuestsAllowed') })
        ),
});

const nightsSchema = Yup.object().shape({
    startDate: Yup.string()
        .required(t('errorStartDate'))
        .test('is-valid-date', t('errorInvalidStartDate'), (value) => {
            return !isNaN(Date.parse(value));
        }),
    endDate: Yup.string()
        .required(t('errorEndDate'))
        .test('is-valid-date', t('errorInvalidEndDate'), (value) => {
            return !isNaN(Date.parse(value));
        })
        .test('minNights', t('errorMinimumNights', { minNights: 2 }), function (value) {
            const { startDate } = this.parent;
            if (!value || !startDate) return true;
            const nights = calculateNights(startDate, value);
            if (nights < 2) {
                return this.createError({
                    message: t('errorMinimumNights', { minNights: 2 })
                });
            }
            return true;
        })
        .test('maxNightsWithGuests', t('errorMaxNightsWithGuests', { maxNights: 7 }), function (value) {
            const { startDate, numberOfGuests } = this.parent;
            if (!value || !startDate) return true;
            const nights = calculateNights(startDate, value);
            if (numberOfGuests > 0 && nights > 7) {
                return this.createError({
                    message: t('errorMaxNightsWithGuests', { maxNights: 7 })
                });
            }
            return true;
        })
        .test('maxNightsWithoutGuests', t('errorMaxNightsWithoutGuests', { maxNights: 15 }), function (value) {
            const { startDate, numberOfGuests } = this.parent;
            if (!value || !startDate) return true;
            const nights = calculateNights(startDate, value);
            if (numberOfGuests === 0 && nights > 15) {
                return this.createError({
                    message: t('errorMaxNightsWithoutGuests', { maxNights: 15 })
                });
            }
            return true;
        }),
});

export { validationSchema, nightsSchema };