import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Typography,
    Pagination,
    Box,
    CircularProgress,
    Backdrop
} from '@mui/material';
import * as Yup from 'yup';
import axios from 'axios';
import { Config } from '../../../utils/configHeader';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

export const GuestModal = ({ open, onClose, reservation }) => {
    // console.log('Reserva:', reservation);

    const { t } = useTranslation();
    const navigate = useNavigate(); // Inicializar navigate
    const guestsPerPage = 2;

    const [guests, setGuests] = useState([]);
    const [touched, setTouched] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (reservation) {
            const totalGuests = reservation.totalPersonas;
            const filteredGuests = reservation.invitados
                .slice(0, totalGuests)
                .map((invitado) => ({
                    type: invitado.type === 'null' ? '' : invitado.type || '',
                    name: invitado.name === 'null' ? '' : invitado.name || '',
                    dni: invitado.dni === 'null' ? '' : invitado.dni || '',
                    relationship: invitado.relationship === 'null' ? '' : invitado.relationship || '',
                    sexo: '', // En zoho este capo existe pero no es mostrado, por ende en caso de futuras actualizaciones se deja vacío
                }));
            setGuests(filteredGuests);
            setTouched(Array(totalGuests).fill({}));
        }
    }, [reservation]);

    const guestValidationSchema = Yup.object().shape({
        type: Yup.string()
            .oneOf(['Adulto', 'Niño'], t('validation.oneOf', { values: `${t('adult')}, ${t('child')}` }))
            .required(t('validation.required')),
        name: Yup.string()
            .min(2, t('validation.minChars', { min: 2 }))
            .required(t('validation.required')),
        dni: Yup.string().required(t('validation.required')),
        relationship: Yup.string().required(t('validation.required')),
    });

    const validateGuests = () => {
        let allValid = true;
        const updatedGuests = guests.map((guest) => {
            try {
                guestValidationSchema.validateSync(guest, { abortEarly: false });
                return { ...guest, error: {} };
            } catch (error) {
                allValid = false;
                const fieldErrors = {};
                error.inner.forEach((err) => {
                    fieldErrors[err.path] = err.message;
                });
                return { ...guest, error: fieldErrors };
            }
        });

        // console.log('Invitados después de la validación:', updatedGuests);
        setGuests(updatedGuests);
        setIsSaveEnabled(allValid);
    };


    const handleChangeGuest = (index, field, value) => {
        const updatedGuests = [...guests];
        updatedGuests[index][field] = value;

        if (updatedGuests[index].error) {
            delete updatedGuests[index].error[field];
        }

        setGuests(updatedGuests);
        validateGuests();
    };

    const handleBlurGuest = (index, field) => {
        const updatedTouched = [...touched];
        updatedTouched[index] = { ...updatedTouched[index], [field]: true };
        setTouched(updatedTouched);
    };

    const handleSaveGuests = async () => {
        if (!isSaveEnabled) {
            Swal.fire({
                icon: 'error',
                title: t('guestValidationErrorTitle'),
                text: t('guestValidationErrorMessage'),
                confirmButtonText: t('tryAgain'),
            });
            return;
        }

        try {
            setIsLoading(true);
            const guestsToSend = guests.map(({ error, ...guest }) => guest);

            const config = Config();
            const response = await axios.put(
                `v1/reservations/${reservation.id}/guests`,
                guestsToSend,
                {
                    ...config,
                    headers: {
                        ...config.headers,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                onClose();
                Swal.fire({
                    icon: 'success',
                    title: t('guestSaveSuccessTitle'),
                    text: t('guestSaveSuccessMessage'),
                    confirmButtonText: 'OK',
                });
                navigate('/gestion-de-reservas');
            } else {
                throw new Error('Error al guardar los invitados');
            }
        } catch (error) {
            console.error('Error al guardar los invitados:', error);
            Swal.fire({
                icon: 'error',
                title: t('guestSaveErrorTitle'),
                text: t('guestSaveErrorMessage'),
                confirmButtonText: t('contactSupport'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const paginatedGuests = guests.slice(
        (currentPage - 1) * guestsPerPage,
        currentPage * guestsPerPage
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
                {t('manageGuests')}
            </DialogTitle>
            <DialogContent dividers sx={{ position: 'relative' }}>
                {/* Backdrop con loader si isLoading es true */}
                {isLoading && (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <Grid container spacing={3} sx={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}>
                    {paginatedGuests.map((guest, index) => (
                        <Grid item xs={12} sm={6} key={(currentPage - 1) * guestsPerPage + index}>
                            <Box
                                sx={{
                                    border: '1px solid #d1d1d1',
                                    borderRadius: '12px',
                                    padding: '1.5rem',
                                }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {t('guestNumber', {
                                        number: (currentPage - 1) * guestsPerPage + index + 1,
                                    })}
                                </Typography>

                                <FormControl fullWidth sx={{ marginBottom: '1rem' }} disabled={isLoading}>
                                    <InputLabel>{t('guestType')}</InputLabel>
                                    <Select
                                        value={guest.type}
                                        onChange={(e) =>
                                            handleChangeGuest(
                                                (currentPage - 1) * guestsPerPage + index,
                                                'type',
                                                e.target.value
                                            )
                                        }
                                        onBlur={() =>
                                            handleBlurGuest(
                                                (currentPage - 1) * guestsPerPage + index,
                                                'type'
                                            )
                                        }
                                        error={!!guest.error?.type && touched[index]?.type}
                                    >
                                        <MenuItem value="Adulto">{t('adult')}</MenuItem>
                                        <MenuItem value="Niño">{t('child')}</MenuItem>
                                    </Select>
                                    {guest.error?.type && touched[index]?.type && (
                                        <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                                            {guest.error.type}
                                        </Typography>
                                    )}
                                </FormControl>

                                <TextField
                                    label={t('guestName')}
                                    value={guest.name}
                                    onChange={(e) =>
                                        handleChangeGuest(
                                            (currentPage - 1) * guestsPerPage + index,
                                            'name',
                                            e.target.value
                                        )
                                    }
                                    onBlur={() =>
                                        handleBlurGuest(
                                            (currentPage - 1) * guestsPerPage + index,
                                            'name'
                                        )
                                    }
                                    fullWidth
                                    sx={{ marginBottom: '1rem' }}
                                    error={!!guest.error?.name && touched[index]?.name}
                                    helperText={guest.error?.name && touched[index]?.name ? guest.error.name : ''}
                                    disabled={isLoading}
                                />

                                <TextField
                                    label={t('guestDNI')}
                                    value={guest.dni}
                                    onChange={(e) =>
                                        handleChangeGuest(
                                            (currentPage - 1) * guestsPerPage + index,
                                            'dni',
                                            e.target.value
                                        )
                                    }
                                    onBlur={() =>
                                        handleBlurGuest(
                                            (currentPage - 1) * guestsPerPage + index,
                                            'dni'
                                        )
                                    }
                                    fullWidth
                                    sx={{ marginBottom: '1rem' }}
                                    error={!!guest.error?.dni && touched[index]?.dni}
                                    helperText={guest.error?.dni && touched[index]?.dni ? guest.error.dni : ''}
                                    disabled={isLoading}
                                />

                                <FormControl fullWidth sx={{ marginBottom: '1rem' }} disabled={isLoading}>
                                    <InputLabel>{t('relationship')}</InputLabel>
                                    <Select
                                        value={guest.relationship}
                                        onChange={(e) =>
                                            handleChangeGuest(
                                                (currentPage - 1) * guestsPerPage + index,
                                                'relationship',
                                                e.target.value
                                            )
                                        }
                                        onBlur={() =>
                                            handleBlurGuest(
                                                (currentPage - 1) * guestsPerPage + index,
                                                'relationship'
                                            )
                                        }
                                        error={!!guest.error?.relationship && touched[index]?.relationship}
                                    >
                                        <MenuItem value="Padre/Madre">{t('parent')}</MenuItem>
                                        <MenuItem value="Hermano/a">{t('sibling')}</MenuItem>
                                        <MenuItem value="Esposo/a">{t('spouse')}</MenuItem>
                                        <MenuItem value="Hijo/a">{t('child')}</MenuItem>
                                        <MenuItem value="Otro">{t('other')}</MenuItem>
                                    </Select>
                                    {guest.error?.relationship && touched[index]?.relationship && (
                                        <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                                            {guest.error.relationship}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {guests.length > guestsPerPage && (
                    <Pagination
                        count={Math.ceil(guests.length / guestsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'center', opacity: isLoading ? 0.5 : 1 }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>{t('cancel')}</Button>
                <Button
                    onClick={handleSaveGuests}
                    color="primary"
                    variant="contained"
                    disabled={!isSaveEnabled || isLoading}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
