import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';

/**
 * Formatea una fecha al formato 'dd/MM/yyyy'.
 * @param {string | Date} date - La fecha a formatear (puede ser una cadena o un objeto Date).
 * @param {string} fallback - Texto que se muestra si la fecha no es válida.
 * @returns {string} - La fecha formateada o el texto de fallback.
 */
export const formatDate = (date, fallback = 'Invalid Date') => {
  try {
    return format(new Date(date), 'dd/MM/yyyy');
  } catch (error) {
    return fallback;
  }
};

const getFormatDate = (value) => {

  if (!value) value = new Date();
  if (typeof value === "string") value = new Date(value);
  const currentDate = value;

  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear().toString();
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export { getFormatDate }

/**
 * Formatea una fecha a un formato legible según la localización especificada.
 *
 * @param {Date | string} date - La fecha a formatear. Si es una cadena, se convierte a objeto Date.
 * @param {string} [locale='es'] - La localización a utilizar ('es' para español, 'en' para inglés).
 * @returns {string} La fecha formateada o 'Fecha inválida' si la fecha no es válida.
 */
export const humanReadableTime = (date, locale = 'es') => {
  try {
    if (!date) date = new Date();
    if (typeof date === "string") date = new Date(date);

    if (isNaN(date)) throw new Error('Fecha inválida');

    const locales = { es, en: enUS };
    const selectedLocale = locales[locale] || enUS;

    // Formato deseado: 'Wed, 18 Dec 2024' o 'Mié, 18 Dic 2024'
    return format(date, 'EEE, dd MMM yyyy', { locale: selectedLocale });
  } catch (error) {
    console.error(error);
    return 'Fecha inválida';
  }
};