import React, { useState } from 'react';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    DialogContent,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import { CustomAxios } from '../../custom/axiosCustom';
import { Config } from '../../utils/configHeader';
import { useUserProfile } from '../../hooks/useUserProfile';
import RenderStepContent from './RenderStepContent/RenderStepContent';
import { locations, steps } from './constants';
import { validationSchema, nightsSchema } from './reservationSchema';

const ReservationFlow = ({
    handleCloseModal,
    mode = 'create',
    initialData = null,
    onReservationCompleted,
}) => {

    const { userData, isReady } = useUserProfile();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [availableRooms, setAvailableRooms] = useState([]);
    const [error, setError] = useState('');
    const [validateFields, setValidateFields] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [maxGuestsAllowed, setMaxGuestsAllowed] = useState(7);
    const [fieldErrors, setFieldErrors] = useState({});
    const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);

    const [reservationData, setReservationData] = useState(() => {
        if (mode === 'edit' && initialData) {
            if (initialData.status === 'Rechazado CEO') {
                Swal.fire({
                    icon: 'error',
                    title: t('errorCannotEditRejectedReservationTitle'),
                    text: t('errorCannotEditRejectedReservationMessage'),
                });
                handleCloseModal();
                return {};
            }

            const assignedRooms = [];
            if (initialData.Habitacion_1) assignedRooms.push(1);
            if (initialData.Habitacion_2) assignedRooms.push(2);
            if (initialData.Habitacion_3) assignedRooms.push(3);

            const initialLocation = locations.find(loc => loc.name === initialData.property)?.id || '';

            return {
                location: initialLocation,
                startDate: initialData.startDate
                    ? new Date(initialData.startDate).toISOString().split('T')[0]
                    : '',
                endDate: initialData.endDate
                    ? new Date(initialData.endDate).toISOString().split('T')[0]
                    : '',
                numberOfGuests: initialData.numberOfGuests || initialData.Total_Personas || 0,
                numberOfRooms: initialData.numberOfRooms || assignedRooms.length || 0,
                assignedRooms: initialData.assignedRooms || assignedRooms,
                status: initialData.status || 'Pendiente Revisión CEO',
            };
        } else {
            return {
                location: '',
                startDate: '',
                endDate: '',
                numberOfGuests: 0,
                numberOfRooms: 0,
                assignedRooms: [],
                status: 'Pendiente Revisión CEO',
            };
        }
    });

    const [completedSteps, setCompletedSteps] = useState(
        Array(steps.length).fill(false)
    );

    const handleNext = async () => {
        setValidateFields(true);
        setFieldErrors({});

        try {
            if (activeStep === 0) {
                await validationSchema.validateAt('location', reservationData, { abortEarly: false });
            }

            if (activeStep === 1) {
                const datesValid = await validateDates();
                if (!datesValid) {
                    return;
                }

                await nightsSchema.validate(reservationData, { abortEarly: false });

                if (!reservationData.isAvailabilityChecked) {
                    setError(t('errorCheckAvailabilityBeforeProceeding'));
                    return;
                }
            }

            if (activeStep === 2) {
                if (reservationData.numberOfRooms < 1 || reservationData.numberOfRooms > availableRooms.length) {
                    setError(t('errorSelectValidNumberOfRooms'));
                    return;
                }

                const assignedRooms = availableRooms.slice(0, reservationData.numberOfRooms);
                setReservationData({ ...reservationData, assignedRooms });
            }

            setActiveStep((prevStep) => prevStep + 1);
            setCompletedSteps((prev) => {
                const updatedSteps = [...prev];
                updatedSteps[activeStep] = true;
                return updatedSteps;
            });

            setError('');
            setValidateFields(false);
        } catch (err) {
            if (err.inner && err.inner.length > 0) {
                const fieldErrors = {};
                err.inner.forEach((validationError) => {
                    fieldErrors[validationError.path] = validationError.message;
                });
                setFieldErrors(fieldErrors);
            } else if (err.errors && Array.isArray(err.errors)) {
                setError(err.errors.join(', '));
            } else {
                console.error('Validation Error:', err);
                setError(t('unexpectedValidationError'));
            }
        }
    };

    const handleBack = () => {
        const updatedCompletedSteps = [...completedSteps];
        updatedCompletedSteps[activeStep] = false;
        setCompletedSteps(updatedCompletedSteps);

        setActiveStep((prevStep) => prevStep - 1);
        setValidateFields(false);
        setError('');
        setFieldErrors({});
    };

    const handleReservation = async () => {
        setIsSubmitting(true);
        try {
            if (!isReady || !userData) {
                setError(t('errorFetchingUserData'));
                setIsSubmitting(false);
                return;
            }

            const selectedLocation = locations.find(loc => loc.id === reservationData.location);
            if (!selectedLocation) {
                setError(t('errorSelectLocation'));
                setIsSubmitting(false);
                return;
            }

            const reservationDataToSend = {
                id: initialData?.id,
                email: userData.companyEmail,
                Departamento: userData.department || 'Operaciones', //TODO: Cambiar por el departamento real
                Propiedad_a_Reservar: selectedLocation.name,
                Fecha_Inicio: new Date(reservationData.startDate).toISOString(),
                Fecha_Fin: new Date(reservationData.endDate).toISOString(),
                Total_Personas: reservationData.numberOfGuests,
                Habitacion_1: reservationData.assignedRooms.includes(1),
                Habitacion_2: reservationData.assignedRooms.includes(2),
                Habitacion_3: reservationData.assignedRooms.includes(3),
                Estatus_Reserva: mode === 'edit' ? 'Pendiente Revisión CEO' : 'Pendiente Revisión Gerente',
            };

            if (mode === 'edit') {
                reservationDataToSend.status = 'Pendiente Revisión CEO';
            }

            let response;

            if (mode === 'create') {
                response = await CustomAxios.post(
                    'reservations',
                    reservationDataToSend,
                    Config()
                );
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: t('reservationSuccessTitle'),
                        text: t('reservationSuccessMessage'),
                        confirmButtonText: 'OK',
                    });
                } else {
                    throw new Error('Error al crear la reserva');
                }
            } else {
                response = await CustomAxios.put(
                    `reservations/${initialData.id}`,
                    reservationDataToSend,
                    Config()
                );
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: t('reservationUpdateSuccessTitle'),
                        text: t('reservationUpdateSuccessMessage'),
                        confirmButtonText: 'OK',
                    });
                } else {
                    throw new Error('Error al actualizar la reserva');
                }
            }
            onReservationCompleted(response.data);
        } catch (error) {
            console.error('Error al procesar la reserva:', error);
            Swal.fire({
                icon: 'error',
                title: t('reservationErrorTitle'),
                text: t('reservationErrorMessage'),
                confirmButtonText: t('contactSupport'),
            });
        } finally {
            setIsSubmitting(false);
        }

        handleCloseModal();
    };

    const getStepIcon = (index) => {
        if (completedSteps[index]) {
            return <CheckCircleIcon color="primary" />;
        }
        return <RadioButtonUncheckedIcon color="disabled" />;
    };

    const calculateMaxGuests = (selectedLocation, availableRooms) => {
        if (!selectedLocation || availableRooms.length === 0) {
            return 0;
        }

        return availableRooms.reduce((totalGuests, roomNumber) => {
            const roomCapacity = selectedLocation.capacities[roomNumber - 1];
            return totalGuests + (roomCapacity || 0);
        }, 0);
    };

    const validateDates = async () => {
        try {
            await validationSchema.validateAt('startDate', reservationData, { abortEarly: false });
            await validationSchema.validateAt('endDate', reservationData, { abortEarly: false });

            await nightsSchema.validate(reservationData, { abortEarly: false });

            setFieldErrors({});
            return true;
        } catch (err) {
            const errors = {};

            if (err.inner && err.inner.length > 0) {
                err.inner.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });
            } else if (err.path && err.message) {
                errors[err.path] = err.message;
            } else {
                console.error('Validation Error:', err);
                setError(t('unexpectedValidationError'));
                return false;
            }

            setFieldErrors(errors);
            return false;
        }
    };

    const fetchAvailableRooms = async () => {
        try {
            setError('');
            const response = await CustomAxios.get(
                `reservations/availability?location=${reservationData.location}&startDate=${reservationData.startDate}&endDate=${reservationData.endDate}`,
                Config()
            );

            if (response.status === 200) {
                const availableRooms = response.data.availableRooms;

                if (availableRooms.length === 0) {
                    setError(t('noAvailabilityOnSelectedDates'));
                    setAvailableRooms([]);
                    setReservationData({
                        ...reservationData,
                        isAvailabilityChecked: false,
                    });
                    return false;
                } else {
                    setAvailableRooms(availableRooms);
                    const selectedLocation = locations.find(loc => loc.id === reservationData.location);
                    const maxGuests = calculateMaxGuests(selectedLocation, availableRooms);
                    setMaxGuestsAllowed(maxGuests);

                    setReservationData({
                        ...reservationData,
                        isAvailabilityChecked: true,
                    });

                    setError('');
                    return true;
                }
            } else {
                setError(t("errorFetchingAvailableRooms"));
                setReservationData({
                    ...reservationData,
                    isAvailabilityChecked: false,
                });
                return false;
            }
        } catch (error) {
            console.error("Error fetching available rooms:", error);
            setError(t("errorFetchingAvailableRooms"));
            setReservationData({
                ...reservationData,
                isAvailabilityChecked: false,
            });
            return false;
        }
    };

    if (!isReady) {
        return (
            <DialogContent
                sx={{
                    minHeight: '55vh',
                    maxHeight: '55vh',
                    overflowY: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </DialogContent>
        );
    }

    return (
        <>
            <DialogContent sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <Stepper activeStep={activeStep} sx={{ mb: 4, px: 2 }}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel StepIconComponent={() => getStepIcon(index)}>
                                {t(label)}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box>
                    <RenderStepContent
                        step={activeStep}
                        reservationData={reservationData}
                        setReservationData={setReservationData}
                        validateFields={validateFields}
                        handleReservation={handleReservation}
                        error={error}
                        fieldErrors={fieldErrors}
                        setFieldErrors={setFieldErrors}
                        setError={setError}
                        isEditMode={mode === 'edit'}
                        existingReservation={initialData}
                        locations={locations}
                        availableRooms={availableRooms}
                        fetchAvailableRooms={fetchAvailableRooms}
                        maxGuestsAllowed={maxGuestsAllowed}
                        validateDates={validateDates}
                        setAvailableRooms={setAvailableRooms}
                        isCheckingAvailability={isCheckingAvailability}
                        setIsCheckingAvailability={setIsCheckingAvailability}
                    />

                </Box>

                <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        {t('back')}
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReservation}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    <CircularProgress size={24} color="inherit" />
                                    &nbsp;
                                    {mode === 'create'
                                        ? t('confirmingReservation')
                                        : t('updatingReservation')}
                                </>
                            ) : mode === 'create' ? (
                                t('confirmReservation')
                            ) : (
                                t('updateReservation')
                            )}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={activeStep === 1 && !reservationData.isAvailabilityChecked}
                        >
                            {t('next')}
                        </Button>

                    )}
                </Box>
            </DialogContent>

            <Backdrop open={isSubmitting} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default ReservationFlow;