/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect} from "react";

// react-router-dom components
import {useLocation, NavLink} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";

// Material Dashboard 2 PRO React context
import {
    useMaterialUIController,
    setMiniSidenav,
    setTransparentSidenav,
    setWhiteSidenav,
} from "../../context";
import {Close} from "@mui/icons-material";
import {useUserData} from "../../hooks/useUserData";
import {usePermission} from "../../hooks/usePermission"
import { userContainPermission} from "../../utils/permission";

function Sidenav({color, brand, brandName, routes, ...rest}) {
    const {t} = useTranslation();
    const [controller, dispatch] = useMaterialUIController();
    const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} = controller;
    const location = useLocation();
    const {rol} = useUserData();
    const collapseName = location.pathname.replace("/", "");
    const { permissionList } = usePermission("Modulos Permitidos PC");
    const existPermission = (permission) => {
        if(permission === "Default"){
            return true;
        }
        return userContainPermission(permission, permissionList);
        // return true; // *** Ocupar esto para visualizar los modulos sin permisos ***
    };

    let textColor = "white";

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    } else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({type, name, icon, title, noCollapse, key, href, route, permission}) => {
        let returnValue;
        if (type === "collapse") {
            returnValue = href ? (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            ) : (
              <>
                {existPermission(permission) && (
                  <NavLink key={key} to={route}>
                    <SidenavCollapse
                      name={t(name)}
                      icon={icon}
                      active={key === collapseName}
                    />
                  </NavLink>
                )}
              </>
            );
        } else if (type === "title") {
            returnValue = (
                <MDTypography
                    key={key}
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="uppercase"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </MDTypography>
            );
        } else if (type === "divider") {
            returnValue = (
                <Divider
                    key={key}
                    light={
                        (!darkMode && !whiteSidenav && !transparentSidenav) ||
                        (darkMode && !transparentSidenav && whiteSidenav)
                    }
                />
            );
        }

        return returnValue;
    });

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{transparentSidenav, whiteSidenav, miniSidenav, darkMode}}
        >
            <MDBox pt={3} pb={1} px={4} textAlign="center">
                <MDBox
                    display={{xs: "block", xl: "none"}}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{cursor: "pointer"}}
                >
                    <MDTypography variant="h6" color="secondary">
                        <Close sx={{fontWeight: "bold"}}/>
                    </MDTypography>
                </MDBox>
                <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                    {brand && <img src={brand} alt="Brand" width="180px" height="100"/>}
                </MDBox>
            </MDBox>
            <Divider
                light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                }
            />
            <List>{renderRoutes}</List>
        </SidenavRoot>
    );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
