export const transformReservationData = (reservation) => {
    const totalHabitacionesDisponibles = [reservation.Habitacion_1, reservation.Habitacion_2, reservation.Habitacion_3].filter(habitacion => habitacion === true).length;

    return {
        id: reservation.id,
        createdAt: reservation.Created_Time,
        email: reservation.email,
        collaborator: reservation.Colaborador !== "null" ? reservation.Colaborador : null,
        department: reservation.Departamento,
        property: reservation.Propiedad_a_Reservar,
        startDate: reservation.Fecha_Inicio,
        endDate: reservation.Fecha_Fin,
        reservaPortalColaboradores: reservation.Reserva_Portal_Colaboradores,
        reservaExclusiva: reservation.Reserva_Exclusiva,
        usuarioAprobadorInicial: reservation.Usuario_Aprobador_Inicial !== "null" ? reservation.Usuario_Aprobador_Inicial : null,
        informacionAprobacionGerente: reservation.Informaci_n_Aprobaci_n_Gerente,
        fechaYHoraAprobacionGerencia: reservation.Fecha_y_Hora_Aprobaci_n_Gerencia,
        status: reservation.Estatus_Reserva,
        fechaYHoraAprobacion: reservation.Fecha_y_Hora_Aprobaci_n,
        totalPersonas: reservation.Total_Personas,
        habitacion1: reservation.Habitacion_1,
        habitacion2: reservation.Habitacion_2,
        habitacion3: reservation.Habitacion_3,
        name: reservation.Name,
        invitados: reservation.invitados,
        totalHabitacionesDisponibles
    };
};
